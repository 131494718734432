export default {
  "app-title": "FBS",
  "app-title-2": "Unsaleable",
  "app-title-language": "Language",
  "title-detail": "Detail",
  "title-welcome_to_use": "Welcome to use",

  //tabBar
  "tabbar-market": "Market",
  "tabbar-order": "Order",
  "tabbar-news": "News",
  "tabbar-mine": "Mine",
  "public-night_mode": "Night Mode",
  "public-noRecord": " No data yet",
  "public-text-cancel": "Cancel",
  "public-text-confirm": "Comfirm",
  "public-text-balance": "Balance",
  "public-text-date": "Date",
  "public-text-completed": "Completed",
  "public-text-invalid": "Invalid",
  "public-text-unchecked": "Unchecked",
  "public-text-remarks": "Remark",
  "public-text-loading": "Loading",
  "public-text-loose-to-refresh": "Loose to refresh...",
  "public-text-buy": "Buy",
  "public-text-sell": "Sell",
  "public-text-close": "Closed",
  "public-text-lots_1": "Lots",
  "public-text-lots": "Lots",
  "public-text-available": "Available",
  "public-text-freeze": "Freeze",
  "public-text-scroll_down_refresh": "Scroll down to refresh...",
  "public-btn-submit": "Submit",
  "public-btn-add": "Add",
  "public-btn-copy": "Copy",
  "public-btn-delete": "Delete",
  "public-btn-copyLink": "Copy Invitation Link",
  "public-btn-send": "Send",
  "public-btn-download": "DOWNLOAD",
  "public-btn-resetPassword": "Reset Password",
  "public-btn-confirmation": "Confirmation",
  "public-btn-accept": "Accept",
  "public-enterEmail": "Enter your Email",
  "public-password": "Password",
  "public-retypePassword": "Re-type the password",
  "public-inviteCode": "Invitation Code",
  "public-verifyCode": "Verification Code",
  "login-newUser": "New User?",
  "login-joinNow": "Join Now",
  "login-forgotPassword": "Forgot Password",
  "login-btn-login": "Login",
  "login-btn-demoAcc":
    "No need to open an account, create a demo account to trade now!",
  "login-enterEmail": "Please enter your email address",
  "login-enterPassword": "Please enter your password",
  "regsiter-btn-register": "Register",
  "regsiter-enterInviteCode": "Please enter the invitation code",
  "regsiter-enterVerityCode": "Please enter the verification code",
  "logout-title": "Logout",
  "systemHint-reset_successful": "Reset successful",
  "systemHint-incorrectVerityCode": "Incorrent Verification Code",
  "systemHint-passwordsDoNotMatch": "Password not match",
  "systemHint-password_error": "Password error",
  "systemHint-login_connot_find_user": "Cannot find this user",
  "systemHint-copySuccess": "Copied Successfully",
  "systemHint-plsCompleted": "Please Completed",
  "systemHint-pwdInconsistent": "Password Inconsistent",
  "systemHint-incorrectVerifyCode": "Incorrect Verification Code",
  "systemHint-changeSuccess": "Change Successfully",
  "systemHint-plsSubmit": "Please submit complete information",
  "systemHint-binb-bank-not-open-yet":
    "Widthdraw of this currency is not yet open",
  "systemHint-plsFillIn": "Please fill in completely",
  "systemHint-requestSuccessful": "Request Successful",
  "systemHint-operation_successful": "Successful Operation",
  "systemHint-set_successful": "Set Successfully",
  "systemHint-missing_params-or-val-error":
    "Missing parameters or passing value error",
  "systemHint-limit_trade_price_must_greater":
    "Limit trade price must be greater than 0",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "Buy(Long) stop loss price cannot be higher than the opening price",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "Buy(Long) stop profit price cannot be higher than the opening price",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "Sell(Short) stop loss price cannot be lower than the opening price",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "Sell(Short) stop profit price cannot be lower than the opening price",
  "systemHint-code_send_succesful": "Send verification code successfully",
  "systemHint-pls_register_login_real_account":
    "Please register or login to a real hard account",
  "systemHint-successful_exit": "Successfully Exit",
  "popupHint-set_up_collection_account":
    "Please set up a collection account first",
  "popupHint-confirm_to_delete": "Are you sure to delete?",
  "popupHint-whether_return_it": "Whether to return it",
  "mine-availableAssets": "Available Assets",
  "mine-account_details": "Account Details",
  "mine-deposit": "Deposit",
  "mine-withdraw": "Widthdraw",
  "mine-billDetails": "Billing Details",
  "mine-demo_return_simulated_account": "Return simulated account",
  "mine-wallet": "Wallet",
  "mine-verifyName": "Real Name Verification",
  "mine-inviteFriend": "Invite Friends",
  "mine-changePassword": "Change Password",
  "mine-onlineSerive": "Online Service",
  "mine-complaintEmail": "Complaint email",
  "mine-announcement": "Announcement",
  "mine-establishment": "Establishment",
  "mine-other": "Other",
  "mine-change_theme": "Change Theme",
  "dialog-title": "Prompt",
  "dialog-logout-conetnt": "Are you sure you want to logout?",
  "announce-text":
    "Due to policy reasons, services are not provided to North Korea, Israel, China, Vanuatu, and Cuba.",
  "announce-text-demo": "(FBS Just for testing)",
  "verify-text-upload":
    "Please upload front and back of passport or driver's license",
  "verify-front": "Front",
  "verify-front-desc": "Upload the front of your passport/certificate",
  "verify-back": "Reverse side",
  "verify-back-desc": "Upload the reverse side of your passport/certificate",
  "verify-text-info": "Please complete your personal information",
  "verify-name": "Name",
  "verify-enterName": "Please enter your name",
  "verify-passportId": "Passport/DL number",
  "verify-enterPassportId": "Please enter your passport/DL number",
  "verify-infoAuth": "Audit",
  "wallet-bind-bank": "Bind bank card",
  "wallet-bind-currency-address": "Bind digital currency address",
  "bind-bank-name": "Bank Name",
  "bind-bank-address": "Bank Address",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "Payee",
  "bind-bank-payee-account": "Payee Account",
  "bind-currency": "Currency",
  "bind-wallet-address": "Wallet Address",
  "deposit-digitai-currency": "Digital Currency",
  "deposit-bank-card": "Bank Card",
  "deposit-select_network": "Select Network",
  "deposit-coin-number": "Number of coins",
  "deposit-coin-placeholder": "Please enter the number of coins to be charged",
  "doposit-upload-cert": "Uploading Certificate",
  "deposit-record": "Deposit Record",
  "deposit-recharge_amount": "Recharge Amount",
  "deposit-recharge-placeholder": "Please enter the recharge amount",
  "deposit-recharge_type": "Type",
  "deposit-recharge_unit": "Unit",
  "deposit-recharge_status": "Status",
  "deposit-receipt_currency": "Receipt Currency",
  "withdraw-coin_address": "Address for coin withdrawals",
  "withdraw-coin_amount": "Amount",
  "withdraw-handling_fee": "Handling fee",
  "withdraw-expected": "Expected Amount to be Credited",
  "withdraw-btn-submit": "Submit withdrawal",
  "withdraw-coin_withdrawal_record": "Coin withdrawal record",
  "withdraw-num_of_receipt": "Number of Receipts",
  "withdraw-select_addess": "Please select an address to call",
  "order-position_holding": "Position holding",
  "order-pending_orders": "Pending Orders",
  "order-history": "History",
  "order-profit_loss": "Profit and Loss",
  "order-current_margin": "Current Margin",
  "order-risk_rate": "Risk Rate",
  "order-order_id": "Orders ID",
  "order-total_profit_loss": "Total Profit and Loss",
  "order-order_details": "Order Details",
  "order-margin": "Margin",
  "order-take_profit": "Take Profit",
  "order-set_loss": "Set Loss",
  "order-set_TPSL": "Set TP/SL",
  "order-close_position": "Close position",
  "order-cancel_order": "Cancel order",
  "order-select_period": "Select period",
  "order-btn_rise": "Rise",
  "order-btn_fall": "Fall",
  "order-estimated_return": "Estimated return",
  "order-purchase_amount": "Purchase amount",
  "search-search_product": "Search Product",
  "search-search_result": "Search Results",
  "trade-market_price": "Market Price",
  "trade-price": "Price",
  "trade-quick_trade": "Quick Trade",
  "trade-please_enter_price": "Please enter the price",
  "trade-each_lots": "Each Lots",
  "trade-estimated_handling_fee": "Estimated Handling Fee",
  "trade-estimated_margin": "Estimated Margin",
  "trade-lots_text": "Lots(Step:0.001)",
  "trade-order_page": "Order Page",
  "trade-your_order_has_been_confirmed": "Your order has been confirmed",
  "trade-multiplier": "Multiplier",
  "accHistory-financial_records": "Financial Records",
  "accHistory-records": "Record",

  Res_Msg1: "Please enter email",
  Res_Msg2: "Invalid email format",
  Res_Msg3: "Please enter password",
  Res_Msg4: "Password and confirm password do not match",
  Res_Msg5: "Password must be at least 6 characters long",
  Res_Msg6: "Incorrect verification code",
  Res_Msg7: "Please enter verification code",
  Res_su: "Registration successful",
  Login_su: "Login successful",
  Loading: "No more data",
  SDealCont_TopName: "Delivery",
  SDealCont_L1: "Purchase Price",
  SDealCont_L2: "Current Price",
  SDealCont_L3: "Direction",
  SDealCont_L4: "Quantity",
  SDealCont_L5: "Delivery Time",
  SDealCont_btn: "Close",
  SDealCont_L6: "Buy Long",
  SDealCont_L7: "Sell Short",
  SDealCont_L8: "Buy",
  SDealCont_L9: "Sell",
  "verify-verified": "Verification successful",
  "verify-notVerified": "Verification failed",
  "加载中...": "Loading...",
  分时: "Time",
  登入密碼: "Please enter the login password",
  登入密碼不能为空: "Login password cannot be empty",

  邮件注册: "Email Registration",
  請輸入手機號: "Please enter the phone number",
  手机号码注册: "Phone Number Registration",
  请输入账号: "Please enter the account",
};
