<template>
    <div id="login" style="min-height: 100vh; flex: 1">
        <topNavBar :leftIcon="true" :rightIcon="false" />
        <div class="k-text text-center text-xl leading-40 h-80">
            <span>{{ t("app-title") }}</span>
            <span>({{ t("app-title-2") }})</span>
        </div>

        <div class="v-px-24">
            <el-form :label-position="'top'" label-width="auto" :model="loginForm">
                <el-form-item :label="$t('请输入账号')" class="blue-label">
                    <el-input v-model="loginForm.Email" name="Email" style="font-size: 3.73333vw; border-radius: 8vw"
                        :placeholder="$t('请输入账号')" autocomplete="请输入账号" />
                </el-form-item>
                <el-form-item :label="$t('public-password')" class="basic-label">
                    <div class="flex items-center v-w-full">
                        <el-input v-model="loginForm.Password" name="Password" style="
                font-size: 3.73333vw;
                border-radius: 8vw;
                margin-right: 2vw;
              " :placeholder="$t('login-enterPassword')" :type="hidePassword ? 'password' : 'text'"
                            autocomplete="Password" />
                        <van-icon :name="hidePassword ? 'closed-eye' : 'eye-o'" style="font-size: 1.4rem"
                            @click="togglePasswordVisibility" />
                    </div>
                </el-form-item>

                <div class="text-container">
                    <div>
                        <span>{{ $t("login-newUser") }}</span>
                        <span class="text-kline-up" @click="navigateTo('register')">{{
                            $t("login-joinNow")
                            }}</span>
                    </div>
                    <div @click="navigateTo('forgetPwd')">
                        <p>{{ $t("login-forgotPassword") }}</p>
                    </div>
                </div>

                <el-form-item>
                    <el-button @click="loginRegularAccount" class="btn-blue" style="
              width: 100%;
              height: 10.66667vw;
              border-radius: 5.33333vw;
              border: 0;
              font-size: 3.7222vw;
              margin-top: 10.66667vw;
            ">
                        {{ $t("login-btn-login") }}
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="createDemoAccount" class="btn-blue" round style="
              width: 100%;
              white-space: normal;
              height: 10.66667vw;
              border-radius: 5.33333vw;
              border: 0;
              font-size: 3.7222vw;
            ">
                        <span>{{ $t("login-btn-demoAcc") }}</span>
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { showToast, showLoadingToast, showSuccessToast } from "/node_modules/vant";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const store = useStore();
const loginForm = reactive({
  Email: "",
  Password: "",
});
const hidePassword = ref(true);

const routes = {
  register: "/pages/login/registerIndex",
  forgetPwd: "/pages/login/forgotPassword",
};

const navigateTo = (path) => {
  const targetPath = routes[path];
  targetPath ? router.push({ path: targetPath }) : "";
};

const togglePasswordVisibility = () => {
  hidePassword.value = !hidePassword.value;
};

async function createDemoAccount() {
    showLoadingToast({
          forbidClick: true,
          loadingType: 'spinner',
          duration: 600
    });
    await store.dispatch("DemoReg");
    router.push("/");
}

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 600,
  });
};

// 登录
const loginRegularAccount = async() => {
    const { Email, Password } = loginForm;
    const emailPattern = /^[a-zA-Z0-9._-]+@(gmail\.com|outlook\.com|yahoo\.com|qq\.com|163\.com|126\.com|hotmail\.com|icloud\.com)$/;

    if (!Email) {
        return showMessage("fail", t("请输入账号"));
    }

    // if (!emailPattern.test(Email)) {
    //     showMessage("fail", t("Res_Msg2"));
    //     return;
    // }

    if (!Password) {
        return showMessage("fail", t("login-enterPassword"));
    }

    if(emailPattern){
        await store.dispatch("Login", { data: { email: loginForm.Email, password: loginForm.Password } })
        showSuccessToast(t('Login_su'));
        router.go(-1)
    }
};
</script>

<style scoped>
:deep(.el-input__wrapper) {
  border-radius: 5vw;
  height: 11vw;
  padding: 0 4vw;
  border: 0 solid;
  box-shadow: 0 0 0 0;
}

:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 0;
}

.text-container {
  font-size: 3.2vw;
  display: flex !important;
  justify-content: space-between !important;
  /* color: rgb(203 203 203 / 1); */
}

/* 为中等屏幕设置样式 */
@media (min-width: 600px) and (max-width: 1200px) {
  :deep(.el-input__wrapper) {
    margin: 3vw 0;
  }
}
</style>
