export default {
  "app-title": "FBS",
  "app-title-2": "अविक्रीत",
  "app-title-language": "भाषा",
  "title-detail": "विवरण",
  "title-welcome_to_use": "उपयोग करने के लिए स्वागत है",

  //tabBar
  "tabbar-market": "बाजार",
  "tabbar-order": "आदेश",
  "tabbar-news": "समाचार",
  "tabbar-mine": "मेरा",
  "public-night_mode": "रात का मोड",
  "public-noRecord": "कोई डेटा नहीं",
  "public-text-cancel": "रद्द करें",
  "public-text-confirm": "पुष्टि",
  "public-text-balance": "शेष",
  "public-text-date": "तिथि",
  "public-text-completed": "पूर्ण",
  "public-text-invalid": "अवैध",
  "public-text-unchecked": "अस्वीकृत",
  "public-text-remarks": "टिप्पणी",
  "public-text-loading": "लोड हो रहा है",
  "public-text-loose-to-refresh": "ताज़ा करने के लिए छोड़ें...",
  "public-text-buy": "खरीदें",
  "public-text-sell": "बेचें",
  "public-text-close": "बंद",
  "public-text-lots_1": "लॉट्स",
  "public-text-lots": "लॉट्स",
  "public-text-available": "उपलब्ध",
  "public-text-freeze": "फ्रीज़",
  "public-text-scroll_down_refresh": "ताज़ा करने के लिए नीचे स्क्रॉल करें...",
  "public-btn-submit": "सबमिट करें",
  "public-btn-add": "जोड़ें",
  "public-btn-copy": "कॉपी करें",
  "public-btn-delete": "हटाएं",
  "public-btn-copyLink": "आमंत्रण लिंक कॉपी करें",
  "public-btn-send": "भेजें",
  "public-btn-download": "डाउनलोड",
  "public-btn-resetPassword": "पासवर्ड रीसेट करें",
  "public-btn-confirmation": "पुष्टि",
  "public-btn-accept": "स्वीकृत करें",
  "public-enterEmail": "अपना ईमेल दर्ज करें",
  "public-password": "पासवर्ड",
  "public-retypePassword": "पासवर्ड पुनः दर्ज करें",
  "public-inviteCode": "आमंत्रण कोड",
  "public-verifyCode": "सत्यापन कोड",
  "login-newUser": "नया उपयोगकर्ता?",
  "login-joinNow": "अब शामिल हों",
  "login-forgotPassword": "पासवर्ड भूल गए",
  "login-btn-login": "लॉगिन",
  "login-btn-demoAcc": "खाता खोलने की आवश्यकता नहीं, अब एक डेमो खाता बनाएं!",
  "login-enterEmail": "कृपया अपना ईमेल पता दर्ज करें",
  "login-enterPassword": "कृपया अपना पासवर्ड दर्ज करें",
  "regsiter-btn-register": "रजिस्टर",
  "regsiter-enterInviteCode": "कृपया आमंत्रण कोड दर्ज करें",
  "regsiter-enterVerityCode": "कृपया सत्यापन कोड दर्ज करें",
  "logout-title": "लॉगआउट",
  "systemHint-reset_successful": "रीसेट सफल",
  "systemHint-incorrectVerityCode": "गलत सत्यापन कोड",
  "systemHint-passwordsDoNotMatch": "पासवर्ड मेल नहीं खाते",
  "systemHint-password_error": "पासवर्ड त्रुटि",
  "systemHint-login_connot_find_user": "इस उपयोगकर्ता को ढूंढा नहीं जा सकता",
  "systemHint-copySuccess": "सफलता से कॉपी किया गया",
  "systemHint-plsCompleted": "कृपया पूरा करें",
  "systemHint-pwdInconsistent": "पासवर्ड असंगत",
  "systemHint-incorrectVerifyCode": "गलत सत्यापन कोड",
  "systemHint-changeSuccess": "परिवर्तन सफल",
  "systemHint-plsSubmit": "कृपया पूरा विवरण सबमिट करें",
  "systemHint-binb-bank-not-open-yet":
    "इस मुद्रा की निकासी अभी तक खुली नहीं है",
  "systemHint-plsFillIn": "कृपया पूरी तरह से भरें",
  "systemHint-requestSuccessful": "अनुरोध सफल",
  "systemHint-operation_successful": "संचालन सफल",
  "systemHint-set_successful": "सेट सफल",
  "systemHint-missing_params-or-val-error": "गायब पैरामीटर या मान त्रुटि",
  "systemHint-limit_trade_price_must_greater":
    "सीमित व्यापार मूल्य 0 से अधिक होना चाहिए",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "खरीद (लंबा) स्टॉप लॉस मूल्य उद्घाटन मूल्य से अधिक नहीं हो सकता",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "खरीद (लंबा) स्टॉप प्रॉफिट मूल्य उद्घाटन मूल्य से अधिक नहीं हो सकता",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "बेचने (छोटा) स्टॉप लॉस मूल्य उद्घाटन मूल्य से कम नहीं हो सकता",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "बेचने (छोटा) स्टॉप प्रॉफिट मूल्य उद्घाटन मूल्य से कम नहीं हो सकता",
  "systemHint-code_send_succesful": "सत्यापन कोड सफलतापूर्वक भेजा गया",
  "systemHint-pls_register_login_real_account":
    "कृपया एक वास्तविक खाता पंजीकृत या लॉगिन करें",
  "systemHint-successful_exit": "सफलतापूर्वक बाहर",
  "popupHint-set_up_collection_account": "कृपया पहले संग्रह खाता सेट करें",
  "popupHint-confirm_to_delete": "क्या आप वाकई हटाना चाहते हैं?",
  "popupHint-whether_return_it": "क्या इसे वापस करना चाहिए",
  "mine-availableAssets": "उपलब्ध संपत्ति",
  "mine-account_details": "खाता विवरण",
  "mine-deposit": "जमा करें",
  "mine-withdraw": "निकासी",
  "mine-billDetails": "बिल विवरण",
  "mine-demo_return_simulated_account": "नकली खाते पर लौटें",
  "mine-wallet": "वॉलेट",
  "mine-verifyName": "वास्तविक नाम सत्यापन",
  "mine-inviteFriend": "मित्रों को आमंत्रित करें",
  "mine-changePassword": "पासवर्ड बदलें",
  "mine-onlineSerive": "ऑनलाइन सेवा",
  "mine-complaintEmail": "शिकायत ईमेल",
  "mine-announcement": "घोषणा",
  "mine-establishment": "स्थापना",
  "mine-other": "अन्य",
  "mine-change_theme": "थीम बदलें",
  "dialog-title": "चेतावनी",
  "dialog-logout-conetnt": "क्या आप वाकई लॉगआउट करना चाहते हैं?",
  "announce-text":
    "नीति कारणों से, उत्तर कोरिया, इज़राइल, चीन, वानुअतु और क्यूबा को सेवाएं प्रदान नहीं की जाती हैं।",
  "announce-text-demo": "(FBS केवल परीक्षण के लिए)",
  "verify-text-upload": "कृपया अपने पासपोर्ट/ID के सामने और पीछे अपलोड करें",
  "verify-front": "सामना",
  "verify-front-desc": "अपने पासपोर्ट/प्रमाण पत्र का सामने अपलोड करें",
  "verify-back": "पीछे",
  "verify-back-desc": "अपने पासपोर्ट/प्रमाण पत्र का पीछे अपलोड करें",
  "verify-text-info": "कृपया अपनी व्यक्तिगत जानकारी पूरी करें",
  "verify-name": "नाम",
  "verify-enterName": "कृपया अपना नाम दर्ज करें",
  "verify-passportId": "पासपोर्ट/ID संख्या",
  "verify-enterPassportId": "कृपया अपना पासपोर्ट/ID नंबर दर्ज करें",
  "verify-infoAuth": "सत्यापन",
  "wallet-bind-bank": "बैंक कार्ड जोड़ें",
  "wallet-bind-currency-address": "डिजिटल मुद्रा पता जोड़ें",
  "bind-bank-name": "बैंक का नाम (बैंक नाम)",
  "bind-bank-address": "बैंक का पता",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "प्राप्तकर्ता",
  "bind-bank-payee-account": "प्राप्तकर्ता खाता",
  "bind-currency": "मुद्रा",
  "bind-wallet-address": "वॉलेट पता",
  "deposit-digitai-currency": "डिजिटल मुद्रा",
  "deposit-bank-card": "बैंक कार्ड",
  "deposit-select_network": "नेटवर्क चुनें",
  "deposit-coin-number": "मुद्राओं की संख्या",
  "deposit-coin-placeholder": "जमा की जाने वाली मुद्राओं की संख्या दर्ज करें",
  "doposit-upload-cert": "प्रमाणपत्र अपलोड करें",
  "deposit-record": "जमा रिकॉर्ड",
  "deposit-recharge_amount": "रिचार्ज राशि",
  "deposit-recharge-placeholder": "कृपया रिचार्ज राशि दर्ज करें",
  "deposit-recharge_type": "प्रकार",
  "deposit-recharge_unit": "इकाई",
  "deposit-recharge_status": "स्थिति",
  "deposit-receipt_currency": "रसीद मुद्रा",
  "withdraw-coin_address": "मुद्रा निकासी पता",
  "withdraw-coin_amount": "राशि",
  "withdraw-handling_fee": "हैंडलिंग शुल्क",
  "withdraw-expected": "अनुमानित जमा राशि",
  "withdraw-btn-submit": "निकासी सबमिट करें",
  "withdraw-coin_withdrawal_record": "मुद्रा निकासी रिकॉर्ड",
  "withdraw-num_of_receipt": "रसीदों की संख्या",
  "withdraw-select_addess": "कृपया कॉल करने के लिए पता चुनें",
  "order-position_holding": "पोजीशन होल्डिंग",
  "order-pending_orders": "लंबित आदेश",
  "order-history": "इतिहास",
  "order-profit_loss": "लाभ और हानि",
  "order-current_margin": "वर्तमान मार्जिन",
  "order-risk_rate": "जोखिम दर",
  "order-order_id": "आदेश ID",
  "order-total_profit_loss": "कुल लाभ और हानि",
  "order-order_details": "आदेश विवरण",
  "order-margin": "मार्जिन",
  "order-take_profit": "लाभ लें",
  "order-set_loss": "हानि सेट करें",
  "order-set_TPSL": "TP/SL सेट करें",
  "order-close_position": "पोजीशन बंद करें",
  "order-cancel_order": "आदेश रद्द करें",
  "order-select_period": "समय अवधि चुनें",
  "order-btn_rise": "उठें",
  "order-btn_fall": "गिरें",
  "order-estimated_return": "अनुमानित लाभ",
  "order-purchase_amount": "खरीद राशि",
  "search-search_product": "उत्पाद खोजें",
  "search-search_result": "खोज परिणाम",
  "trade-market_price": "बाजार मूल्य",
  "trade-price": "मूल्य",
  "trade-quick_trade": "त्वरित व्यापार",
  "trade-please_enter_price": "कृपया मूल्य दर्ज करें",
  "trade-each_lots": "प्रत्येक लॉट",
  "trade-estimated_handling_fee": "अनुमानित हैंडलिंग शुल्क",
  "trade-estimated_margin": "अनुमानित मार्जिन",
  "trade-lots_text": "लॉट्स (चरण: 0.001)",
  "trade-order_page": "आदेश पृष्ठ",
  "trade-your_order_has_been_confirmed": "आपका आदेश पुष्टि हो चुका है",
  "trade-multiplier": "गुणक",
  "accHistory-financial_records": "वित्तीय रिकॉर्ड",
  "accHistory-records": "रिकॉर्ड",

  Res_Msg1: "कृपया ईमेल दर्ज करें",
  Res_Msg2: "ईमेल प्रारूप गलत है",
  Res_Msg3: "कृपया पासवर्ड दर्ज करें",
  Res_Msg4: "पासवर्ड और पुनरावृत्ति पासवर्ड मेल नहीं खाते",
  Res_Msg5: "पासवर्ड की लंबाई कम से कम 6 अक्षर होनी चाहिए",
  Res_Msg6: "सत्यापन कोड गलत है",
  Res_Msg7: "कृपया सत्यापन कोड दर्ज करें",
  Res_su: "पंजीकरण सफल",
  Login_su: "लॉगिन सफल",
  Loading: "कोई और डेटा नहीं",
  SDealCont_TopName: "डिलिवरी",
  SDealCont_L1: "खरीद मूल्य",
  SDealCont_L2: "वर्तमान मूल्य",
  SDealCont_L3: "दिशा",
  SDealCont_L4: "मात्रा",
  SDealCont_L5: "डिलिवरी समय",
  SDealCont_btn: "बंद करें",
  SDealCont_L6: "लंबा खरीदें",
  SDealCont_L7: "छोटा बेचें",
  SDealCont_L8: "खरीदें",
  SDealCont_L9: "बेचें",
  "verify-verified": "सत्यापन सफल",
  "verify-notVerified": "सत्यापन विफल",
  "加载中...": "लोड हो रहा है...",
  分时: "समय",
  登入密碼: "कृपया लॉगिन पासवर्ड दर्ज करें",
  登入密碼不能为空: "लॉगिन पासवर्ड खाली नहीं हो सकता",

  邮件注册: "ईमेल पंजीकरण",
  請輸入手機號: "कृपया मोबाइल नंबर दर्ज करें",
  手机号码注册: "मोबाइल नंबर पंजीकरण",
  请输入账号: "कृपया खाता दर्ज करें",
};
