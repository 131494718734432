export default {
  "app-title": "FBS",
  "app-title-2": "Tidak Terjual",
  "app-title-language": "Bahasa",
  "title-detail": "Detail",
  "title-welcome_to_use": "Selamat datang",

  //tabBar
  "tabbar-market": "Pasar",
  "tabbar-order": "Pesanan",
  "tabbar-news": "Berita",
  "tabbar-mine": "Saya",
  "public-night_mode": "Mode Malam",
  "public-noRecord": "Belum ada data",
  "public-text-cancel": "Batal",
  "public-text-confirm": "Konfirmasi",
  "public-text-balance": "Saldo",
  "public-text-date": "Tanggal",
  "public-text-completed": "Selesai",
  "public-text-invalid": "Tidak Valid",
  "public-text-unchecked": "Belum Diperiksa",
  "public-text-remarks": "Keterangan",
  "public-text-loading": "Memuat",
  "public-text-loose-to-refresh": "Lepaskan untuk menyegarkan...",
  "public-text-buy": "Beli",
  "public-text-sell": "Jual",
  "public-text-close": "Tutup",
  "public-text-lots_1": "Lot",
  "public-text-lots": "Lot",
  "public-text-available": "Tersedia",
  "public-text-freeze": "Bekukan",
  "public-text-scroll_down_refresh": "Gulir ke bawah untuk menyegarkan...",
  "public-btn-submit": "Kirim",
  "public-btn-add": "Tambah",
  "public-btn-copy": "Salin",
  "public-btn-delete": "Hapus",
  "public-btn-copyLink": "Salin Tautan Undangan",
  "public-btn-send": "Kirim",
  "public-btn-download": "UNDUH",
  "public-btn-resetPassword": "Atur Ulang Kata Sandi",
  "public-btn-confirmation": "Konfirmasi",
  "public-btn-accept": "Terima",
  "public-enterEmail": "Masukkan Email Anda",
  "public-password": "Kata Sandi",
  "public-retypePassword": "Ketik ulang kata sandi",
  "public-inviteCode": "Kode Undangan",
  "public-verifyCode": "Kode Verifikasi",
  "login-newUser": "Pengguna Baru?",
  "login-joinNow": "Bergabung Sekarang",
  "login-forgotPassword": "Lupa Kata Sandi",
  "login-btn-login": "Masuk",
  "login-btn-demoAcc":
    "Tidak perlu membuka akun, buat akun demo untuk bertransaksi sekarang!",
  "login-enterEmail": "Silakan masukkan alamat email Anda",
  "login-enterPassword": "Silakan masukkan kata sandi Anda",
  "regsiter-btn-register": "Daftar",
  "regsiter-enterInviteCode": "Silakan masukkan kode undangan",
  "regsiter-enterVerityCode": "Silakan masukkan kode verifikasi",
  "logout-title": "Keluar",
  "systemHint-reset_successful": "Reset berhasil",
  "systemHint-incorrectVerityCode": "Kode Verifikasi Salah",
  "systemHint-passwordsDoNotMatch": "Kata sandi tidak cocok",
  "systemHint-password_error": "Kesalahan kata sandi",
  "systemHint-login_connot_find_user": "Pengguna tidak dapat ditemukan",
  "systemHint-copySuccess": "Berhasil disalin",
  "systemHint-plsCompleted": "Silakan lengkapi",
  "systemHint-pwdInconsistent": "Kata sandi tidak konsisten",
  "systemHint-incorrectVerifyCode": "Kode Verifikasi Salah",
  "systemHint-changeSuccess": "Perubahan Berhasil",
  "systemHint-plsSubmit": "Silakan kirimkan informasi lengkap",
  "systemHint-binb-bank-not-open-yet": "Penarikan mata uang ini belum dibuka",
  "systemHint-plsFillIn": "Silakan isi dengan lengkap",
  "systemHint-requestSuccessful": "Permintaan Berhasil",
  "systemHint-operation_successful": "Operasi Berhasil",
  "systemHint-set_successful": "Pengaturan Berhasil",
  "systemHint-missing_params-or-val-error":
    "Parameter hilang atau nilai yang diteruskan salah",
  "systemHint-limit_trade_price_must_greater":
    "Harga perdagangan terbatas harus lebih besar dari 0",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "Harga stop loss beli (panjang) tidak boleh lebih tinggi dari harga pembukaan",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "Harga stop profit beli (panjang) tidak boleh lebih tinggi dari harga pembukaan",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "Harga stop loss jual (pendek) tidak boleh lebih rendah dari harga pembukaan",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "Harga stop profit jual (pendek) tidak boleh lebih rendah dari harga pembukaan",
  "systemHint-code_send_succesful": "Kode verifikasi berhasil dikirim",
  "systemHint-pls_register_login_real_account":
    "Silakan daftarkan atau masuk ke akun nyata",
  "systemHint-successful_exit": "Keluar Berhasil",
  "popupHint-set_up_collection_account":
    "Silakan atur akun koleksi terlebih dahulu",
  "popupHint-confirm_to_delete": "Apakah Anda yakin ingin menghapus?",
  "popupHint-whether_return_it": "Apakah harus dikembalikan?",
  "mine-availableAssets": "Aset Tersedia",
  "mine-account_details": "Detail Akun",
  "mine-deposit": "Setor",
  "mine-withdraw": "Tarik",
  "mine-billDetails": "Detail Tagihan",
  "mine-demo_return_simulated_account": "Kembali ke akun simulasi",
  "mine-wallet": "Dompet",
  "mine-verifyName": "Verifikasi Nama",
  "mine-inviteFriend": "Undang Teman",
  "mine-changePassword": "Ganti Kata Sandi",
  "mine-onlineSerive": "Layanan Online",
  "mine-complaintEmail": "Email Keluhan",
  "mine-announcement": "Pengumuman",
  "mine-establishment": "Pendirian",
  "mine-other": "Lainnya",
  "mine-change_theme": "Ganti Tema",
  "dialog-title": "Peringatan",
  "dialog-logout-conetnt": "Apakah Anda yakin ingin keluar?",
  "announce-text":
    "Karena alasan kebijakan, layanan tidak disediakan untuk Korea Utara, Israel, China, Vanuatu, dan Kuba.",
  "announce-text-demo": "(FBS Hanya untuk pengujian)",
  "verify-text-upload":
    "Silakan unggah bagian depan dan belakang paspor/ID Anda",
  "verify-front": "Depan",
  "verify-front-desc": "Unggah bagian depan paspor/sertifikat Anda",
  "verify-back": "Belakang",
  "verify-back-desc": "Unggah bagian belakang paspor/sertifikat Anda",
  "verify-text-info": "Silakan lengkapi informasi pribadi Anda",
  "verify-name": "Nama",
  "verify-enterName": "Silakan masukkan nama Anda",
  "verify-passportId": "Nomor Paspor/ID",
  "verify-enterPassportId": "Silakan masukkan nomor paspor/ID Anda",
  "verify-infoAuth": "Verifikasi",
  "wallet-bind-bank": "Ikat Kartu Bank",
  "wallet-bind-currency-address": "Ikat Alamat Mata Uang Digital",
  "bind-bank-name": "Nama Bank",
  "bind-bank-address": "Alamat Bank",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "Penerima",
  "bind-bank-payee-account": "Rekening Penerima",
  "bind-currency": "Mata Uang",
  "bind-wallet-address": "Alamat Dompet",
  "deposit-digitai-currency": "Mata Uang Digital",
  "deposit-bank-card": "Kartu Bank",
  "deposit-select_network": "Pilih Jaringan",
  "deposit-coin-number": "Jumlah Koin",
  "deposit-coin-placeholder":
    "Silakan masukkan jumlah koin yang akan disetorkan",
  "doposit-upload-cert": "Unggah Sertifikat",
  "deposit-record": "Rekaman Setoran",
  "deposit-recharge_amount": "Jumlah Isi Ulang",
  "deposit-recharge-placeholder": "Silakan masukkan jumlah isi ulang",
  "deposit-recharge_type": "Tipe",
  "deposit-recharge_unit": "Satuan",
  "deposit-recharge_status": "Status",
  "deposit-receipt_currency": "Mata Uang Kwitansi",
  "withdraw-coin_address": "Alamat Penarikan Koin",
  "withdraw-coin_amount": "Jumlah",
  "withdraw-handling_fee": "Biaya Penanganan",
  "withdraw-expected": "Jumlah yang diperkirakan akan dikreditkan",
  "withdraw-btn-submit": "Kirim Penarikan",
  "withdraw-coin_withdrawal_record": "Rekaman Penarikan Koin",
  "withdraw-num_of_receipt": "Jumlah Kwitansi",
  "withdraw-select_addess": "Silakan pilih alamat untuk dipanggil",
  "order-position_holding": "Posisi Ditahan",
  "order-pending_orders": "Pesanan Tertunda",
  "order-history": "Riwayat",
  "order-profit_loss": "Keuntungan dan Kerugian",
  "order-current_margin": "Margin Saat Ini",
  "order-risk_rate": "Tingkat Risiko",
  "order-order_id": "ID Pesanan",
  "order-total_profit_loss": "Total Keuntungan dan Kerugian",
  "order-order_details": "Detail Pesanan",
  "order-margin": "Margin",
  "order-take_profit": "Ambil Keuntungan",
  "order-set_loss": "Atur Kerugian",
  "order-set_TPSL": "Atur TP/SL",
  "order-close_position": "Tutup Posisi",
  "order-cancel_order": "Batalkan Pesanan",
  "order-select_period": "Pilih Periode",
  "order-btn_rise": "Naik",
  "order-btn_fall": "Turun",
  "order-estimated_return": "Perkiraan Pengembalian",
  "order-purchase_amount": "Jumlah Pembelian",
  "search-search_product": "Cari Produk",
  "search-search_result": "Hasil Pencarian",
  "trade-market_price": "Harga Pasar",
  "trade-price": "Harga",
  "trade-quick_trade": "Perdagangan Cepat",
  "trade-please_enter_price": "Silakan masukkan harga",
  "trade-each_lots": "Setiap Lot",
  "trade-estimated_handling_fee": "Perkiraan Biaya Penanganan",
  "trade-estimated_margin": "Perkiraan Margin",
  "trade-lots_text": "Lot (Langkah: 0.001)",
  "trade-order_page": "Halaman Pesanan",
  "trade-your_order_has_been_confirmed": "Pesanan Anda telah dikonfirmasi",
  "trade-multiplier": "Pengali",
  "accHistory-financial_records": "Catatan Keuangan",
  "accHistory-records": "Rekaman",

  Res_Msg1: "Silakan masukkan email",
  Res_Msg2: "Format email tidak valid",
  Res_Msg3: "Silakan masukkan kata sandi",
  Res_Msg4: "Kata sandi dan konfirmasi kata sandi tidak cocok",
  Res_Msg5: "Kata sandi harus memiliki panjang setidaknya 6 karakter",
  Res_Msg6: "Kode verifikasi salah",
  Res_Msg7: "Silakan masukkan kode verifikasi",
  Res_su: "Pendaftaran berhasil",
  Login_su: "Login berhasil",
  Loading: "Tidak ada data lebih lanjut",
  SDealCont_TopName: "Pengiriman",
  SDealCont_L1: "Harga beli",
  SDealCont_L2: "Harga saat ini",
  SDealCont_L3: "Arah",
  SDealCont_L4: "Jumlah",
  SDealCont_L5: "Waktu pengiriman",
  SDealCont_btn: "Tutup",
  SDealCont_L6: "Beli panjang",
  SDealCont_L7: "Jual pendek",
  SDealCont_L8: "Beli",
  SDealCont_L9: "Jual",
  "verify-verified": "Verifikasi berhasil",
  "verify-notVerified": "Verifikasi gagal",
  "加载中...": "Memuat...",
  分时: "Waktu",
  登入密碼: "Silakan masukkan kata sandi login",
  登入密碼不能为空: "Kata sandi login tidak boleh kosong",

  邮件注册: "Pendaftaran email",
  請輸入手機號: "Silakan masukkan nomor telepon",
  手机号码注册: "Pendaftaran nomor telepon",
  请输入账号: "Silakan masukkan akun",
};
